import { QuotationStatus } from "../types/QuotationStatus";
import api from "./api";

export class QuotationStatusService {

  async getQuotationStatus( id: string ): Promise<QuotationStatus> {
    return api.get( `/quotation-status/${ id }` ).then( res => res.data )
  }

  async getQuotationStatuses(): Promise<QuotationStatus[]> {
    return api.get( '/quotation-status' ).then( res => res.data )
  }
}