import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.defaults.headers['Content-Type'] = 'application/json';

api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('@Sigamaq:token');

        if(token){
            config.headers = { Authorization: `Bearer ${token}` }
        }

        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const networkError = error.response === undefined;
    const unauthorizedError = error.response?.status === 401;

    if (unauthorizedError) {
      localStorage.removeItem('@Sigamaq:token');
      localStorage.removeItem('@Sigamaq:user');

      //window.location.replace('/');
    }

    return Promise.reject(error);
  },
);

export default api;
