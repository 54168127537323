import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { ListBox } from 'primereact/listbox';

import { ProfileService } from '../../../service/ProfileService';
import { getFormErrorMessage } from '../../../utilities/Utils';
import { Profile } from '../../../types/Profile';
import { RolesService } from '../../../service/RolesService';
import { useToast } from '../../../contexts/Toast';

export const CreateEditProfile = () => {
    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const { showSuccess, showError } = useToast();

    const [profile, setProfile] = useState<Profile>({} as Profile);
    const [formData, setFormData] = useState({});
    const [showMessage, setShowMessage] = useState(false);
    const [roles, setRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);

    const profileService = new ProfileService();
    const rolesService = new RolesService();

    useEffect(() => {
        if(id && profile.id === undefined){
            profileService.getProfileByID(id)
                .then(data => {
                    setProfile(data);
                    setSelectedRoles(data.roles);
                    setFormData(data);
            });
        }

        if(roles.length === 0){
            rolesService.getRoles().then(data => setRoles(data));
        }
    });

    const { register, formState: { errors }, handleSubmit, control } = useForm();

    const status = [{name: 'Ativo', key: true}, {name: 'Inativo', key: false}];

    const onSubmit = async (data) => {
        setFormData(data);
        setShowMessage(true);

        profile.roles = selectedRoles;

        let result = null;
        if(profile.id){
            result = await profileService.updateProfile(id, profile);
        }else{
            result = await profileService.createProfile(profile);
        }

        if(result.status === 201 || result.status === 200){
            showSuccess("Peril de usuário salvo com sucesso.");
            history.push(`/register/profile`);
        }
    };

    const onInputChange = (e, name) => {
        let val = '';

        if(e.target){
            val = (typeof e.target.value === "object" && e.target.value.key) || e.target.value;
        }

        let _profile = { ...profile };
        _profile[`${name}`] = val;

        setProfile(_profile);
    }

    return (
        <div>
            <h3>Cadastro/Edição de Perfil de Usuário</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Nome *</label>
                        <InputText id="name" name="name" defaultValue={profile.name} {...register("name", { required: 'Nome é obrigatório.' })} 
                            onChange={(e) => onInputChange(e, 'name')} className={classNames({ 'p-invalid': errors.name })} autoFocus />
                        {getFormErrorMessage(errors, 'name')}
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="isActive" className={classNames({ 'p-error': !!errors.isActive })}>Status</label>
                        <Controller name="isActive" control={control} defaultValue={true} rules={{ required: "Status é obrigatório." }} render={(props) => (
                            <SelectButton optionLabel="name" optionValue="key" value={profile.isActive} options={status} onChange={(e) => onInputChange(e, 'isActive')}/>
                        )} />
                        {getFormErrorMessage(errors, 'isActive')}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="roles" className={classNames({ 'p-error': errors.roles })}>Papéis</label>
                        <Controller name="roles" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                            <ListBox value={selectedRoles} options={roles as any} onChange={(e) => {field.onChange(e.value); setSelectedRoles(e.value)}} 
                                multiple optionLabel="name" optionValue="id" style={{ width: '30rem' }} 
                                className={classNames({ 'p-invalid': fieldState.invalid })} id={field.name}/>
                        )} />
                    </div>
                </div>
                <Button type="submit" label="Salvar" icon="pi pi-save" className="p-button-primary"/>
            </form>
        </div>
    );
}
