import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Quotation } from "../../../types/Quotation";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getFormErrorMessage } from "../../../utilities/Utils";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { QuotationService } from "../../../service/QuotationService";
import { useHistory, useParams } from "react-router-dom";
import { useToast } from "../../../contexts/Toast";
import { Deals } from "../../../types/Deals";
import { PiperunService } from "../../../service/PiperunService";
import { Dropdown } from "primereact/dropdown";
import { ClientService } from "../../../service/ClientService";
import { Client } from "../../../types/Client";
import { QuotationStatusService } from "../../../service/QuotationStatusService";
import { QuotationStatus } from "../../../types/QuotationStatus";
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface dealsOption {

  label: string
  value: number
}

interface statusOption {

  label: string
  value: string
}

export const CreateEditQuotations = () => {

    const { id } = useParams<{ id: string }>()

    const { showSuccess, showError } = useToast()

    const [ quotation, setQuotation ] = useState<Quotation>( {} as Quotation )
    const [ formData, setFormData ] = useState( {} )
    const [ showMessage, setShowMessage ] = useState( false )
    const [ piperunDeals, setPiperunDeals ] = useState( Array<dealsOption>() )
    const [ selectedOption, setSelectedOption ] = useState<Deals>( {} as Deals )
    const [ client, setClient ] = useState<Client>( {} as Client )
    const [ showQuotationForm, setShowQuotationForm ] = useState( id ? true : false )
    const [ selectedStatus, setSelectedStatus ] = useState<QuotationStatus>( {} as QuotationStatus )
    const [ quotationStatus, setQuotationStatus ] = useState( Array<statusOption>() )
    const [showDealsModal, setShowDealsModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const { register, formState: { errors }, handleSubmit, control, reset, setValue } = useForm()

    const quotationService = new QuotationService()
    const quotationStatusService = new QuotationStatusService()
    const piperunService = new PiperunService()
    const clientService = new ClientService()
    const history = useHistory()

    const filteredDeals = piperunDeals.filter(deal =>
      deal.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect( () => {

      if ( id ) {
        quotationService.getQuotationById( id )
          .then( data => {
            setQuotation( data )
            setFormData( data )
            setSelectedStatus( data.status )

            reset( { ...data } )
          })
      }
console.log( quotation )
      if ( id && quotation.postal_code ) {
        setValue('postal_code', '852654125', { shouldValidate: true });
      }

      const fetchOptions = async () => {

        const deals = await piperunService.getPiperunDeals()
        const fetchedDeals = deals.map( ( deal ) => ({

          label: `${ deal.id } - ${ deal.title }`,
          value: deal.id
        }))

        const statuses = await quotationStatusService.getQuotationStatuses()
        const fetchedStatuses = statuses.map( ( status ) => ({

          label: `${ status.name }`,
          value: `${ status.id }`
        }))

        setQuotationStatus( fetchedStatuses )
        setPiperunDeals( fetchedDeals )
      }

      fetchOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id, quotation.postal_code, setValue, reset ])

    const updateClientField = async ( id: number ) => {

      const piperunDeal = await piperunService.getPiperunDeal( id )

      if ( piperunDeal ) {

        const piperunCompany = await piperunService.getPiperunCompany( piperunDeal?.company_id )
        const client = await clientService.findByCompanyId( piperunCompany.id )

        setClient( client )

        let _quotation = { ...quotation }
        _quotation.client_id = client.id
        _quotation.postal_code = piperunCompany.cep

        setQuotation( _quotation );
      }
    }

    const onInputChange = ( e: any, name: any ) => {

      let val = null;

      if ( e.target ) {
          val = ( typeof e.target.value === "object" && e.target.value.key ) || e.target.value
      } else if ( e.value ) {
          val = e.value;
      }

      let _quotation = { ...quotation }
      _quotation[ `${name}` ] = val;
      setQuotation( _quotation )
    }

    const onSubmit = async ( data: typeof quotation ) => {

        setFormData( data )
        setShowMessage( true )

        let result = null

        if ( quotation.id ) {

            result = await quotationService.updateQuotation( id, quotation )
        } else {
            result = await quotationService.createQuotation( quotation )
        }

        if ( result.status === 201 || result.status === 200 ) {

            showSuccess( "Nova cotação registrada" )
            history.push( `/frete/quotations/quotation/${ id ? quotation.id : result.data.id }` )
        } else {

          showError( "Falha ao criar cotação" )
        }
    }

    return (

        <div>

            <h3>Cadastro/Edição de Cotações</h3>
            <form onSubmit={ handleSubmit( onSubmit )}>

              <div className="p-fluid p-formgrid p-grid ">

                <div className="p-field p-col-4">
                  <label htmlFor="piperunDeals">Oportunidade</label>
                  <Button
                    label={selectedOption.id?.toString() || 'Selecionar Oportunidade'}
                    icon="pi pi-search"
                    onClick={() => setShowDealsModal(true)}
                  />
                </div>

                <div className="p-field p-col-6">

                  <label htmlFor="client_name">Cliente</label>
                    <InputText
                        id="client_name"
                        name="client_name"
                        value={ client.name }
                        readOnly
                    />
                    <InputText
                        id="client_id"
                        name="client_id"
                        defaultValue={ quotation.client_id }
                        { ...register( "client_id" ) }
                        onChange={ ( e ) => onInputChange( e, "client_id" ) }
                        hidden
                        readOnly
                    />
                </div>
              </div>

                { showQuotationForm && (
              <>
              <div className="p-fluid p-formgrid p-grid">

                <div className="p-field p-col-4">

                    <label htmlFor="status" className={ classNames( { 'p-error': errors.weight } ) }>Status da Cotação</label>
                    <Dropdown
                        name="status"
                        value={ selectedStatus }
                        options={ quotationStatus }
                        onChange={ ( e ) => {
                            setSelectedStatus( e.target.value )
                        }}
                    />

                </div>
            </div>

            <div className="p-fluid p-formgrid p-grid">

                <div className="p-field p-col">

                    <label htmlFor="postal_code" className={ classNames( { 'p-error': errors.postal_code } ) }>CEP</label>
                    <InputText
                        id="postal_code"
                        name="postal_code"
                        defaultValue={ quotation.postal_code }
                        {...register( "postal_code" ) }
                        onChange={ ( e ) => onInputChange( e, "postal_code" ) }
                        className={ classNames( { 'p-invalid': errors.postal_code } ) }
                        autoFocus
                    />
                    { getFormErrorMessage( errors, 'postal_code' ) }
                </div>
                <div className="p-field p-col">

                    <label htmlFor="weight" className={ classNames( { 'p-error': errors.weight } ) }>Peso</label>
                    <Controller
                      control={ control }
                      name="weight"
                      rules={ { required: "Peso é obrigatório." } }
                      defaultValue={ quotation.weight }
                      render={ ( { field } ) => (
                        <InputNumber
                          id="weight"
                          value={ field.value }
                          mode="decimal"
                          minFractionDigits={3}
                          suffix=" kg"
                          onValueChange={ ( e ) => field.onChange( e.value ) }
                          onChange={ ( e ) => onInputChange( e, "weight" ) }
                          className={ classNames( { 'p-invalid': errors.weight } ) }
                        />
                      )}
                    />
                    { getFormErrorMessage( errors, 'weight' ) }
                </div>
                <div className="p-field p-col">

                    <label htmlFor="value" className={ classNames( { 'p-error': errors.value } ) }>Valor da carga</label>
                    <Controller
                      control={ control }
                      name="value"
                      rules={ { required: "Valor é obrigatório." } }
                      defaultValue={ quotation.value }
                      render={ ( { field } ) => (
                        <InputNumber
                          id="value"
                          value={ field.value }
                          mode="decimal"
                          minFractionDigits={2}
                          prefix="R$ "
                          onValueChange={ ( e ) => field.onChange( e.value ) }
                          onChange={ ( e ) => onInputChange( e, "value" ) }
                          className={ classNames( { 'p-invalid': errors.value } ) }
                        />
                      )}
                    />
                    { getFormErrorMessage( errors, 'value' ) }
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">

                <div className="p-field p-col">

                    <label htmlFor="width" className={ classNames( { 'p-error': errors.width } ) }>Largura</label>
                    <Controller
                      control={ control }
                      name="width"
                      rules={ { required: "Largura é obrigatório." } }
                      defaultValue={ quotation.width }
                      render={ ( { field } ) => (
                        <InputNumber
                          id="width"
                          value={ field.value }
                          suffix=" cm"
                          onValueChange={ ( e ) => field.onChange( e.value ) }
                          onChange={ ( e ) => onInputChange( e, "width" ) }
                          className={ classNames( { 'p-invalid': errors.width } ) }
                        />
                      )}
                    />
                    { getFormErrorMessage( errors, 'width' ) }
                </div>
                <div className="p-field p-col">

                    <label htmlFor="height" className={ classNames( { 'p-error': errors.height } ) }>Altura</label>
                    <Controller
                      control={ control }
                      name="height"
                      rules={ { required: "Largura é obrigatório." } }
                      defaultValue={ quotation.height }
                      render={ ( { field } ) => (
                        <InputNumber
                          id="height"
                          value={ field.value }
                          suffix=" cm"
                          onValueChange={ ( e ) => field.onChange( e.value ) }
                          onChange={ ( e ) => onInputChange( e, "height" ) }
                          className={ classNames( { 'p-invalid': errors.height } ) }
                        />
                      )}
                    />
                    { getFormErrorMessage( errors, 'height' ) }
                </div>
                <div className="p-field p-col">

                    <label htmlFor="lenght" className={ classNames( { 'p-error': errors.lenght } ) }>Comprimento</label>
                    <Controller
                      control={ control }
                      name="lenght"
                      rules={ { required: "Largura é obrigatório." } }
                      defaultValue={ quotation.lenght }
                      render={ ( { field } ) => (
                        <InputNumber
                          id="lenght"
                          value={ field.value }
                          suffix=" cm"
                          onValueChange={ ( e ) => field.onChange( e.value ) }
                          onChange={ ( e ) => onInputChange( e, "lenght" ) }
                          className={ classNames( { 'p-invalid': errors.lenght } ) }
                        />
                      )}
                    />
                    { getFormErrorMessage( errors, 'lenght' ) }
                </div>
            </div>
            <div>
                <Button type="submit" label="Consultar frete" icon="pi pi-save" className="p-button-primary"/>
            </div>
            </>
                )}
          </form>

          <Dialog
            header="Selecionar Oportunidade"
            visible={showDealsModal}
            style={{ width: '50vw' }}
            modal
            onHide={() => setShowDealsModal(false)}
          >
            <div className="p-inputgroup">
              <InputText
                placeholder="Pesquisar Oportunidade"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <DataTable
              value={filteredDeals}
              selectionMode="single"
              onSelectionChange={(e) => {
                setSelectedOption(e.value);
                updateClientField(e.value.value);
                setShowDealsModal(false);
                setShowQuotationForm(true);
              }}
              paginator
              rows={10}
            >
              <Column field="label" header="Oportunidade" />
            </DataTable>
          </Dialog>
        </div>
    );
}
