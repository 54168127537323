import { Operation } from "../types/Operation";
import api from "./api";

export class OperationService {

  async getOperation( id: string ): Promise<Operation> {

    return api.get( `/operation/${ id }` ).then( res => res.data )
  }

  async getOperations(): Promise<Operation[]> {

    return api.get( '/operation' ).then( res => res.data )
  }
}