import { Quotation } from "../types/Quotation";
import api from "./api";

export class QuotationService {
    async getQuotations() {
        return api.get( 'quotation' ).then( res => res.data )
    }

    async getQuotationById( id: string ) {
        return api.get( `quotation/${ id }` ).then( res => res.data )
    }

    async getQuotationValues( id: string ) {
        return api.get( `quotation/${ id }/values` ).then( res => res.data )
    }

    async createQuotation( data: Quotation ) {
        return api.post( 'quotation', data )
    }

    async updateQuotation( id: string, data: Quotation ) {
        return api.put( `quotation/${ id }`, data )
    }

    async deleteQuotation( id: string ) {

      return api.delete( `quotation/${ id }` )
    }
}