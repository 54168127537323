import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { Destination } from "../../../../types/Destination"
import { ShippingtaxService } from "../../../../service/ShippingTaxService"
import { ShippingTax } from "../../../../types/ShippingTax"
import { InputText } from "primereact/inputtext"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Operation } from "../../../../types/Operation"
import { OperationService } from "../../../../service/OperationService"
import { OperationOver } from "../../../../types/OperationOver"
import { OperationOverService } from "../../../../service/OperationOver"

export const ViewShippingTax = () => {

  const dt = useRef( null )

  const { id } = useParams<{ id: string }>()

  const [ destinations, setDestinations ] = useState( Array<Destination>() )
  const [ shippingTax, setShippingTax ] = useState<ShippingTax>()
  const shippingTaxService = new ShippingtaxService()
  const operationService = new OperationService()
  const operationOverService = new OperationOverService()

  const [ globalFilter1, setGlobalFilter1 ] = useState(null)

  useEffect( () => {

    shippingTaxService.getShippingTax( id ).then( data => setShippingTax( data ) )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setShippingTax ])

  useEffect( () => {

    shippingTaxService.getDestinations( id ).then( data => setDestinations( data ) )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setDestinations ])

  const header = (
    <div className="table-header">
      <div className="p-grid">
        <div className="p-col">

          <h5 className="p-mb-1">
            Taxas
          </h5>
        </div>
        <div className="p-col">

          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={ (e) => setGlobalFilter1( (e.target as HTMLInputElement ).value ) }
              placeholder="Pesquisar fretes"
            />
          </span>
        </div>
      </div>
    </div>
  )

  const GetOperation = ( { operation_id } ) => {

    const [ operation, setOperation ] = useState<Operation>()

    useEffect( () => {

      operationService.getOperation( operation_id ).then( data => setOperation( data ) )
    }, [ operation_id ] )

    return(

      <span>
        { operation?.name }
      </span>
    )
  }

  const GetOperationOver = ( { operation_over_id } ) => {

    const [ operationOver, setOperationOver ] = useState<OperationOver>()

    useEffect( () => {

      operationOverService.getOperationOver( operation_over_id ).then( data => setOperationOver( data ) )
    }, [ operation_over_id ] )

    return(

      <span>
        { operationOver?.name }
      </span>
    )
  }

  const valueContent = ( rowData: { value: number } ) => {

    return (

      <span>
        R$ { rowData.value }
      </span>
    )
  }

  return (

    <div>
      <h3>

        {shippingTax ? shippingTax.name : 'Carregando...'}
      </h3>
      <div className="pg-grid p-fluid dashboard">

        <div className="p-col-12">

          <DataTable
            ref={ dt }
            value={ destinations }
            dataKey="id"
            paginator rows={ 10 } rowsPerPageOptions={ [ 5, 10, 25 ] }
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
            globalFilter={ globalFilter1 }
            emptyMessage="Nenhuma taxa de frete encontrada." header={ header }
          >

            <Column field="description" header="Descrição" sortable></Column>
            <Column body={ valueContent } header="Valor" sortable></Column>
            <Column field="min_weight" header="Peso Mínimo" sortable></Column>
            <Column field="max_weight" header="Peso Máximo" sortable></Column>
            <Column field="min_value" header="Valor Mínimo" sortable></Column>
            <Column body={ ( rowData ) => <GetOperation operation_id={ rowData.operation_id } /> } header="Tipo de operação"></Column>
            <Column body={ ( rowData ) => <GetOperationOver operation_over_id={ rowData.operation_over_id } /> } header="Operação sobre"></Column>
          </DataTable>
        </div>
      </div>
    </div>
  )
}

export{}