import { OperationOver } from "../types/OperationOver";
import api from "./api";

export class OperationOverService {

  async getOperationOver( id: string ): Promise<OperationOver> {

    return api.get( `/operation-over/${ id }` ).then( res => res.data )
  }

  async getOperationOvers(): Promise<OperationOver[]> {

    return api.get( '/operation' ).then( res => res.data )
  }
}