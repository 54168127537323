import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { SelectButton } from 'primereact/selectbutton';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { ListBox } from 'primereact/listbox';

import { getFormErrorMessage } from '../../../utilities/Utils';
import { UserService } from '../../../service/UserService';
import { ProfileService } from '../../../service/ProfileService';
import { User } from '../../../types/User';
import { useToast } from '../../../contexts/Toast';

export const CreateEditUser = () => {
    const { id } = useParams<{id: string}>();
    const history = useHistory();
    const { showSuccess, showError } = useToast();

    const [user, setUser] = useState<User>({} as User);
    const [profiles, setProfiles] = useState([]);
    const [selectedProfiles, setSelectedProfiles] = useState(null);
    const userTypes = [{name: 'Cliente', key: 1}, {name: 'Funcionário', key: 2}];
    const [formData, setFormData] = useState({});
    const [showMessage, setShowMessage] = useState(false);

    const userService = new UserService();
    const profileService = new ProfileService();

    useEffect(() => {
        if(id){
            userService.getUserByID(id)
                .then(data => {
                    setUser(data);
                    setFormData(data);
                    setSelectedProfiles(data.profiles);
                });
        }

        if(profiles.length === 0){
            profileService.getProfiles().then(data => setProfiles(data));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { register, formState: { errors }, handleSubmit, control, setValue } = useForm();

    useEffect(() => {
        if (id && user.email) {
            setValue('email', user.email, { shouldValidate: true });
        }
    }, [id, user.email, setValue]);

    const onSubmit = async (data) => {
        setFormData(data);
        setShowMessage(true);

        let result = null;
        user.profiles = selectedProfiles;

        if(user.id){
            result = await userService.updateUser(id, user);
        }else{
            result = await userService.createUser(user);
        }

        if(result.status === 201 || result.status === 200){
            showSuccess("Usuário salvo com sucesso.");
            history.push(`/register/user/`);
        }
    };

    const status = [{name: 'Ativo', key: true}, {name: 'Inativo', key: false}];

    const onInputChange = (e, name) => {
        let val = e.target ? e.target.value : e.value;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: val
        }));
        setValue(name, val);
        }

    return (
        <div>
            <h3>Cadastro/Edição de Usuários</h3>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="p-fluid p-formgrid p-grid">

                    <div className="p-field p-col">

                        <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>Usuário *</label>
                        <InputText
                            id="name"
                            name="name"
                            defaultValue={ user.name }
                            { ...register( "name", { required: 'Usuário é obrigatório.' } ) }
                            onChange={ ( e ) => onInputChange( e, 'name' ) }
                            className={ classNames( { 'p-invalid': errors.name } ) }
                            autoFocus
                        />
                        { getFormErrorMessage( errors, 'name' ) }
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>Senha *</label>
                        <Password
                            id="password"
                            name="password"
                            { ...register( "password", { required: user.id === undefined ? 'Senha é obrigatória.' : false } ) }
                            className={ classNames( { 'p-invalid': errors.password } ) }
                            onChange={ ( e ) => onInputChange( e, 'password' ) }
                            toggleMask
                            feedback={ false }
                        />
                        {getFormErrorMessage(errors, 'password')}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">

                    <div className="p-field p-col">

                        <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>E-mail *</label>
                        <InputText
                            id="email"
                            name="email"
                            type="email"
                            defaultValue={ user.email }
                            {...register( "email", { required: 'Email é obrigatório.',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: 'Informe um e-mail valido. E.g. exemplo@email.com'
                                }
                            })}
                            onChange={ ( e ) => onInputChange( e, 'email') }
                            className={ classNames( { 'p-invalid': errors.email } ) }
                        />
                        { getFormErrorMessage( errors, 'email' ) }
                    </div>
                    <div className="p-field p-col">

                        <label htmlFor="type" className={ classNames( { 'p-error': !!errors.userType })}>Tipo de Usuário</label>
                        <Controller
                            name="type"
                            control={ control }
                            defaultValue={ 1 }
                            rules={ { required: "Tipo de Usuário é obrigatório." } }
                            render={ ( {
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                                } ) => (
                                    <SelectButton
                                        optionLabel="name"
                                        optionValue="key"
                                        value={ user.type }
                                        options={userTypes}
                                        onChange={ ( e ) => onInputChange( e, 'type' ) }
                                    />
                                )
                            }
                        />
                        {getFormErrorMessage(errors, 'userType')}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col">
                        <label htmlFor="avatar">Foto</label>
                        <FileUpload id="avatar" name="avatar" mode="basic" accept="image/*" />
                    </div>
                    <div className="p-field p-col">
                        <label htmlFor="isActive" className={classNames({ 'p-error': !!errors.isActive })}>Status</label>
                        <Controller name="isActive" control={control} defaultValue={true} rules={{ required: "Status é obrigatório." }} render={(props) => (
                            <SelectButton optionLabel="name" optionValue="key" value={user.isActive} options={status} onChange={(e) => onInputChange(e, 'isActive')}/>
                        )} />
                        {getFormErrorMessage(errors, 'isActive')}
                    </div>
                </div>
                <div className="p-fluid">
                    <div className="p-field">
                        <h5>Perfis do Usuário</h5>
                    </div>
                    <div className="p-field">
                        <ListBox value={selectedProfiles} options={profiles} onChange={(e) => setSelectedProfiles(e.value)} 
                            multiple optionLabel="name" optionValue="id" style={{ width: '30rem' }} listStyle={{ maxHeight: '300px' }}/>
                    </div>
                </div>
                <Button type="submit" label="Salvar" icon="pi pi-save" className="p-button-primary"/>
            </form>
        </div>
    );
}