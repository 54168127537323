import React, { useState, useEffect, useContext, createContext, useCallback } from 'react';
import { AuthenticationService } from '../service/AuthenticationService';
import { User } from '../types/User';
import { useToast } from './Toast';

interface AuthContextData {
    user: User;
    token: string;
    loading: boolean;
    signed: boolean;
    signIn(credentials: {}): Promise<boolean>;
    signOut(): void;
  }

const AuthContext = createContext({} as AuthContextData);

interface AuthState{
    user: User;
    token: string;
}

const AuthProvider = ({ children }) => {
    const [data, setData] = useState<AuthState>({} as AuthState);
    const authenticationService = new AuthenticationService();
    const [loading, setLoading] = useState(true);

    const { showError } = useToast();

    const signIn = useCallback(async (credentials) => {
        const authResult = await authenticationService.auth(credentials);

        if(authResult.user){
            let userState = { ...authResult }
            userState.isSigned = true;

            setData(userState);

            localStorage.setItem('@Sigamaq:token', authResult.token);
            localStorage.setItem('@Sigamaq:user', JSON.stringify(authResult.user));

            return true;
        }

        showError("Credenciais Inválidas");

        return false;
    }, []);

    const signOut = useCallback(async () => {
        localStorage.removeItem('@Sigamaq:token');
        localStorage.removeItem('@Sigamaq:user');

        setData({} as AuthState);
    }, []);

    useEffect(() => {
        async function loadStoredData(){
            const token = localStorage.getItem('@Sigamaq:token');
            const user = localStorage.getItem('@Sigamaq:user');

            if (token && user) {
                setData({ token: token, user: JSON.parse(user) });
            }else{
                setData({} as AuthState);
            }

            setLoading(false);
        }

        loadStoredData();
    }, []);

    useEffect(() => {
        if (data.user) {
          localStorage.setItem('@Sigamaq:user', JSON.stringify(data.user));
        }
      }, [data.user]);

    return (
        <AuthContext.Provider
            value={{
                user: data.user,
                signed: !!data.user,
                token: data.token,
                loading,
                signIn,
                signOut
            }}>
            { children }
        </AuthContext.Provider>
    );
}

function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if(!context){
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}

export { AuthProvider, useAuth }