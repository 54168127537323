import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { Shipping } from "../../../types/Shipping";
import { ShippingService } from "../../../service/ShippingService";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";

export const ListShippings = () => {

  const history = useHistory()
  const dt = useRef( null )

  const [ shipping, setShipping ] = useState( Array<Shipping>() )
  const [ globalFilter, setGlobalFilter ] = useState(null)
  const shippingService = new ShippingService()

  useEffect( () => {

    shippingService.getShippings().then( data => setShipping( data ) )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setShipping ])

  const header = (
    <div className="table-header">
      <div className="p-grid">
        <div className="p-col">

          <h5 className="p-mb-1">
            Transportadoras
          </h5>
        </div>
        <div className="p-col">

          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={ (e) => setGlobalFilter( (e.target as HTMLInputElement ).value ) }
              placeholder="Pesquisar transportadoras"
            />
          </span>
        </div>
      </div>
    </div>
  )

  const actionBodyContent = ( rowData: { id: string; } ) => {

    return (

      <div className="actions">
        <Button
          icon="bi bi-eye"
          className="p-button-secondary p-mr-2"
          onClick={ () => history.push( `/frete/shippings/shipping/${ rowData.id }` )}
        />
      </div>
    )
  }

  return (
    <div className="pg-grid p-fluid dashboard">

      <div className="p-col-12">

        <DataTable
          ref={ dt }
          value={ shipping }
          dataKey="id"
          paginator rows={ 10 } rowsPerPageOptions={ [ 5, 10, 25 ] }
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
          globalFilter={ globalFilter }
          emptyMessage="Nenhuma transportadora encontrada." header={ header }
        >

          <Column field="cnpj" header="CNPJ" sortable></Column>
          <Column field="name" header="Nome" sortable></Column>
          <Column field="email" header="E-mail" sortable></Column>
          <Column body={ actionBodyContent }></Column>
        </DataTable>
      </div>
    </div>
  );
}
