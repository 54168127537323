import { ShippingTax } from "../types/ShippingTax";
import { Destination } from "../types/Destination";
import api from "./api";

export class ShippingtaxService {

  async getShippingTaxes() {

    return api.get( "/shipping-tax" ).then( res => res.data )
  }

  async getShippingTax( id: string ) {

    return api.get( `/shipping-tax/${ id }` ).then( res => res.data )
  }

  async getShippingTaxByShipping( id: string ) {

    return api.get( `/shipping-tax/${ id }/shipping-taxes` ).then( res => res.data )
  }
  async createShippingTax( data: ShippingTax ) {

    return api.post( "/shipping-tax", data )
  }

  async getDestinations( id: string ): Promise<Destination[]> {

    return api.get( `/shipping-tax/${id}/destinations` ).then( res => res.data )
  }
}