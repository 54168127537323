import { useHistory, useParams } from "react-router-dom";
import { QuotationService } from "../../../service/QuotationService";
import { useEffect, useRef, useState } from "react";
import { Quotation } from "../../../types/Quotation";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Shipping } from "../../../types/Shipping";
import { ShippingService } from "../../../service/ShippingService";
import { Client } from "../../../types/Client";
import { ClientService } from "../../../service/ClientService";

export const ViewQuotation = () => {

  const { id } = useParams<{ id: string}>()

  const history = useHistory()
  const dt = useRef( null )

  const quotationService = new QuotationService()
  const shippingService = new ClientService()
  const [ quotation, setQuotation ] = useState<Quotation>( { value: null } as Quotation)
  const [ values, setValues ] = useState([])

  useEffect( () => {

    quotationService.getQuotationById( id ).then( data => setQuotation( data ) )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setQuotation ] )

  useEffect( () => {

    quotationService.getQuotationValues( id ).then( data => setValues( data ) )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setValues ])

  const header = (
    <div className="table-header">
      <div className="p-grid">
        <div className="p-col">

          <h5 className="p-mb-1">
            Fretes disponíveis
          </h5>
        </div>
      </div>
    </div>
  )

  const GetShippingName = ( { shipping_id } ) => {

    const [ shipping, setShipping ] = useState<Client>()

    useEffect( () => {

      shippingService.getClientByID( shipping_id ).then( data => setShipping( data ) )
    }, [ shipping_id ])

    return (
      <span>
        { shipping?.name }
      </span>
    )
  }

  const timeBody = ( rowData: { min_time: number; max_time: number; } ) => {

    if ( rowData.min_time !== 0 && rowData.max_time !== 0 ) {

      return (
        <span>
          De { rowData.min_time } a { rowData.max_time } dias.
        </span>
      )
    }

    return (
      <span>
        Prazo não definido.
      </span>
    )
  }

  return (
    <div className="p-grid p-fluid dashboard">
      <div className="p-col-11">

        <h3>Visualizar cotação</h3>
      </div>
      <div className="p-col-1">


        <Button
          icon="bi bi-pen"
          className="p-button-warning p-mr-2"
          onClick={ () => history.push( `/frete/quotations/quotation/${ quotation.id }/edit` )}
        />
        <Button
          icon="bi bi-trash"
          className="p-button-danger"
          onClick={ () => history.push( `/frete/quotations/quotation/${ quotation.id }/delete` )}
        />
      </div>
      <div className="p-col-12">
        <Panel className="p-p-0">
          <div className="card p-p-0 p-m-5">
            <div className="p-grid">
              <div className="p-col">
                <span className="p-text-bold">CEP de destino:</span>
                <h5>
                  { quotation.postal_code }
                </h5>
              </div>
              <div className="p-col">
                <span className="p-text-bold">Valor da carga:</span>
                <h5>
                  R$ { quotation.value }
                </h5>
              </div>
              <div className="p-col">
                <span className="p-text-bold">Peso:</span>
                <h5>
                  { quotation.weight } kg
                </h5>
              </div>
              <div className="p-col">
                <span className="p-text-bold">Altura: </span>
                <h5>
                  { quotation.height } cm
                </h5>
              </div>
              <div className="p-col">
                <span className="p-text-bold">Largura: </span>
                <h5>
                  { quotation.width } cm
                </h5>
              </div>
              <div className="p-col">
                <span className="p-text-bold">Comprimento: </span>
                <h5>
                  { quotation.lenght } cm
                </h5>
              </div>
            </div>
          </div>
        </Panel>
      </div>
      <div className="p-col-12">

        <DataTable
          ref={ dt }
          value={ values }
          dataKey="id"
          className="datatable-responsive"
          currentPageReportTemplate="Mostrando { first } até { last } de { totalRecords }"
          emptyMessage="Nenhuma cotação encontrada." header={ header }
        >

          <Column body={ ( rowData ) => <GetShippingName shipping_id={ rowData.shipping_id } /> } header="Transportadora" sortable></Column>
          <Column field="value" header="Custo" sortable></Column>
          <Column body={ timeBody } header="Prazo"></Column>
        </DataTable>
      </div>
    </div>
  );
}
