import { useEffect, useRef, useState } from "react"
import { ClientService } from "../../../service/ClientService"
import { useHistory, useParams } from "react-router-dom"
import { Shipping } from "../../../types/Shipping"
import { DataTable } from "primereact/datatable"
import { ShippingTax } from "../../../types/ShippingTax"
import { ShippingtaxService } from "../../../service/ShippingTaxService"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"

export const ViewShipping = () => {

  const { id } = useParams<{ id: string }>()

  const history = useHistory()
  const dt = useRef( null )

  const [ shipping, setShipping ] = useState<Shipping>()
  const [ shippingTax, setShippingTax ] = useState( Array<ShippingTax>() )

  const shippingService = new ClientService()
  const shippingtaxService = new ShippingtaxService()

  const [ globalFilter, setGlobalFilter ] = useState(null)

  useEffect( () => {

    shippingService.getClientByID( id ).then( data => setShipping( data ))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setShipping ] )

  useEffect( () => {

    shippingtaxService.getShippingTaxByShipping( id ).then( data => setShippingTax( data ) )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setShippingTax ] )

  const header = (
    <div className="table-header">
      <div className="p-grid">
        <div className="p-col">

          <h5 className="p-mb-1">
            Tabela de frete
          </h5>
        </div>
        <div className="p-col">

          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={ (e) => setGlobalFilter( (e.target as HTMLInputElement ).value ) }
              placeholder="Pesquisar fretes"
            />
          </span>
        </div>
      </div>
    </div>
  )

  const actionBodyContent = ( rowData: { id: string, shipping_id: string } ) => {

    return (

      <div className="actions">
        <Button
          icon="bi bi-eye"
          className="p-button-secondary p-mr-2"
          onClick={ () => history.push( `/frete/shippingTaxes/shipping-tax/${ rowData.id }` )}
        />
      </div>
    )
  }

  return(

    <div>
      <h3>
        {shipping ? shipping.name : 'Carregando...'}
      </h3>
      <div className="pg-grid p-fluid dashboard">

        <div className="p-col-12">

          <DataTable
            ref={ dt }
            value={ shippingTax }
            dataKey="id"
            paginator rows={ 10 } rowsPerPageOptions={ [ 5, 10, 25 ] }
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
            globalFilter={ globalFilter }
            emptyMessage="Nenhuma opção de frete encontrada." header={ header }
          >

            <Column field="name" header="Nome" sortable></Column>
            <Column field="description" header="Descrição" sortable></Column>
            <Column body={ actionBodyContent }></Column>
          </DataTable>
        </div>
      </div>
    </div>
  )
}