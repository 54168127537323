import { useEffect, useRef, useState } from "react";
import { QuotationService } from "../../../service/QuotationService";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { QuotationStatusService } from "../../../service/QuotationStatusService";
import { QuotationStatus } from "../../../types/QuotationStatus";
import { Client } from "../../../types/Client";
import { ClientService } from "../../../service/ClientService";

export const ListQuotations = () => {

  const history = useHistory()
  const dt = useRef( null )
  const quotationService = new QuotationService()
  const quotationStatusService = new QuotationStatusService()
  const clientService = new ClientService()

  const [ quotations, setQuotations ] = useState([])
  const [ globalFilter, setGlobalFilter ] = useState(null)

  useEffect( () => {

    quotationService.getQuotations().then( data => setQuotations( data ) )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setQuotations ])

  const header = (
    <div className="table-header">
      <div className="p-grid">
        <div className="p-col">

          <h5 className="p-mb-1">
            Cotações
          </h5>
        </div>
        <div className="p-col">

          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={ (e) => setGlobalFilter( (e.target as HTMLInputElement ).value ) }
              placeholder="Pesquisar cotações"
            />
          </span>
        </div>
      </div>
    </div>
  )

  const actionBodyContent = ( rowData: { id: string; } ) => {

    return (

      <div className="actions">
        <Button
          icon="bi bi-eye"
          className="p-button-secondary p-mr-2"
          onClick={ () => history.push( `/frete/quotations/quotation/${ rowData.id }` )}
        />
        <Button
          icon="bi bi-pen"
          className="p-button-warning p-mr-2"
          onClick={ () => history.push( `/frete/quotations/quotation/${ rowData.id }/edit` )}
        />
        <Button
          icon="bi bi-trash"
          className="p-button-danger"
          onClick={ () => history.push( `/frete/quotations/quotation/${ rowData.id }/delete` )}
        />
      </div>
    )
  }

  const GetStatusName = ( { status } ) => {

    const [ quotationStatus, setQuotationStatus ] = useState<QuotationStatus>()

    useEffect( () => {

      quotationStatusService.getQuotationStatus( status ).then( data => setQuotationStatus( data ) )
    }, [ status ])

    return (
      <span>
        { quotationStatus?.name }
      </span>
    )
  }

  const GetClientName = ( { client_id } ) => {

    const [ client, setClient ] = useState<Client>()

    if ( client_id ) {

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect( () => {

        clientService.getClientByID( client_id ).then( data => setClient( data ) )
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [ client_id ])

      return (
        <span>
          { client?.name }
        </span>
      )
    } else {

      return(
        <span>
          Sem cliente atribuído.
        </span>
      )
    }
  }

  return (
    <div className="pg-grid p-fluid dashboard">

      <div className="p-col-3 p-lg-3">

        <Button onClick={ () => history.push( '/frete/quotations/create' ) } icon="pi pi-plus" label="Nova cotação"/>
      </div>

      <div className="p-col-12">

        <DataTable
          ref={ dt }
          value={ quotations }
          dataKey="id"
          paginator rows={ 10 } rowsPerPageOptions={ [ 5, 10, 25 ] }
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
          globalFilter={ globalFilter }
          emptyMessage="Nenhuma cotação encontrada." header={ header }
        >

          <Column body={ ( rowData ) => <GetClientName client_id={ rowData.client_id } /> } header="Cliente" sortable></Column>
          <Column field="value" header="Valor" sortable></Column>
          <Column field="postal_code" header="CEP de Destino" sortable></Column>
          <Column body={ ( rowData ) => <GetStatusName status={ rowData.status } /> } header="Situação" sortable></Column>
          <Column body={ actionBodyContent }></Column>
        </DataTable>
      </div>
    </div>
  );
}
