import { Deals } from "../types/Deals";
import { PiperunCompany } from "../types/PiperunCompany";
import api from "./api";

export class PiperunService {

  async getPiperunDeals(): Promise<Deals[]> {

    return api.get( "/piperun/deals" ).then( res => res.data )
  }

  async getPiperunDeal( id: number ): Promise<Deals> {

    return api.get( `/piperun/deals/${ id }` ).then( res => res.data )
  }

  async getPiperunCompany( id: number ): Promise<PiperunCompany> {

    return api.get( `/piperun/companies/${ id }` ).then( res => res.data )
  }
}