import { Client } from '../types/Client';
import api from './api';

export class ClientService {
    async getClients() {
        return api.get('persons')
                    .then(res => res.data);
    }

    async getClientByID(id: string) {
        return api.get(`persons/${id}`)
                    .then(res => res.data);
    }

    async create(data: Client) {
        return api.post(`persons`, data);
    }

    async update(id: string, data: Client) {
        return api.post(`persons/${id}`, data);
    }

    async disable(id: string) {
        return api.post(`persons/disable/${id}`)
                    .then(res => res.data);
    }

    async findByCompanyId( piperun_company_id: number ): Promise<Client> {

      return api.get( `/persons/${ piperun_company_id }/company` ).then( res => res.data )
    }
}