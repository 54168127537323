import { useHistory, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { Quotation } from "../../../types/Quotation"
import { QuotationService } from "../../../service/QuotationService"
import { Button } from "primereact/button"
import { useToast } from "../../../contexts/Toast"

export const ConfirmDeleteQuotation = () => {

  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const { showSuccess, showError } = useToast()

  const [ quotation, setQuotation ] = useState<Quotation>( {} as Quotation )
  const [ showMessage, setShowMessage ] = useState( false )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const quotationService = new QuotationService()

  useEffect( () => {

    quotationService.getQuotationById( id ).then( data => setQuotation( data ) )
  }, [ quotationService, id ])

  const confirmDelete = async () => {

    setShowMessage( true )

    const result = await quotationService.deleteQuotation( quotation.id )

    if ( result.status === 200 ) {

        showSuccess( "Cotação excluida com sucesso." )
        history.push( `/frete/quotations/` )
    } else {

      showError( "Falha ao criar cotação" )
    }
  }

  return(

    <div>

      <div>

        <h3>
          Confirmar exclusão?
        </h3>
        <p>

          Você tem certeza que deseja excluir esta cotação?
        </p>
      </div>
      <div className="p-col-6">


        <Button
          label="Cancelar"
          className="p-button-warning p-mr-2"
          onClick={ () => history.push( `/frete/quotations` )}
        />
        <Button
          label="Excluir"
          className="p-button-danger"
          onClick={ confirmDelete }
        />
      </div>
    </div>
  )
}