import api from "./api";

export class ShippingService {

  async getShippings() {

    return api.get( 'shipping' ).then( res => res.data )
  }

  async getShipping( id: string ) {

    return api.get( `shipping/${ id }` ).then( res => res.data )
  }
}